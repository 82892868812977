<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{path:'/'}"><b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>内容管理</el-breadcrumb-item>
        <el-breadcrumb-item>首页幻灯</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="24" class="warp-main">
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input v-model="filters.name" placeholder="请输入名称" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="reqGetBannerListPage" icon="search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="goReset" icon="search">重置</el-button>
          </el-form-item>
          <el-form-item >
            <el-button type="primary" icon="plus" v-if="permissionButton.add" @click="$router.push({path:'/banner/add'})">新增</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-table :data="banner" highlight-current-row v-loading="listLoading" @selection-change="selsChange" style="width: 100%;" border>
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column type="index" width="60" label="序号" align="center"></el-table-column>
        <!--<el-table-column prop="columns" label="栏目" width="120" align="center" sortable :formatter="formatColumns"></el-table-column>-->
        <el-table-column prop="imageUrl" label="幻灯图片" width="130" align="center">
          <template slot-scope="scope" >
            <img style="width: 89px; height: 50px" :src="downloadFileURL+scope.row.imageUrl+'/'"/>
          </template>
        </el-table-column>
        <el-table-column prop="bannerName" label="名称" min-width="200"></el-table-column>
        <el-table-column prop="type" width="120" label="类型" align="center" sortable>
          <template slot-scope="scope" >
            <span>{{scope.row.type==='1'?'首页幻灯':scope.row.type==='2'?'服务幻灯':''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="isUse" label="是否启用" width="120" align="center" sortable>
          <template slot-scope="scope">
            <span v-bind:class="{temporary: (scope.row.isUse)==='2'}">{{scope.row.isUse==='1'?'启用':(scope.row.isUse==='2'?'未启用':(scope.row.isUse==='3'?'草稿':'状态错误!!!'))}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="numberOrder" label="排序" width="80" align="center" sortable></el-table-column>
        <el-table-column prop="formatUpdateTime" label="修改时间" width="180" align="center" sortable >
          <template slot-scope="scope">
            <span>{{scope.row.formatUpdateTime ? scope.row.formatUpdateTime : "暂无更改"}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="formatCreateTime" label="创建时间" width="180" align="center" sortable></el-table-column>
        <el-table-column label="操作" width="240" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button size="small" v-if="permissionButton.view" @click="$router.push({path:'/banner/view',query:{id:scope.row.id}})">查看</el-button>
            <el-button type="primary" size="small" v-if="permissionButton.edit" @click="$router.push({path:'/banner/edit',query:{id:scope.row.id}})">编辑</el-button>
            <el-button type="danger" @click="delBanner(scope.$index,scope.row)" size="small" v-if="permissionButton.del">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-col :span="24" class="table-footer">
        <el-button type="danger" size="small" @click="batchDeleteBanner" :disabled="this.sels.length===0" v-if="permissionButton.batchDel">批量删除</el-button>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10,15,20,25,50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total" style="float:right;">
        </el-pagination>
      </el-col>
    </el-col>
  </el-row>
</template>
<script>
  import util from '../../../common/util'
  import {reqGetDictionaryListByCode,downloadFileURL} from '../../../api/system-api'
  import {reqGetBannerListPage,reqDeleteBanner,reqBatchDeleteBanner}  from '../../../api/banner-api'
  import permission from '../../../common/permission';
  export default{
    data(){
      return{
        filters:{
          name:'',
        },
          typeArray:[
              {text:'首页幻灯',value:'1'},
              {text:'静态广告',value:'2'},
          ],
          downloadFileURL:downloadFileURL,
          columnsTypeArray:[],
        banner:[],
        total:0,
        pageNum:1,
        pageSize:15,
        listLoading:false,
        sels:[],
        permissionButton:new permission.PermissionButton,
      }
    },methods:{
      handleCurrentChange(val) {
        this.pageNum = val;
        this.reqGetBannerListPage();
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageSize=val;
        this.reqGetBannerListPage();
      },

      reqGetBannerListPage() {
        let para = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.filters.name,
        };
        this.listLoading = true;
        reqGetBannerListPage(para).then((res) => {
          this.total = res.data.data.total;
          this.banner = res.data.data.records;
          this.listLoading = false;
        })
      },
      selsChange: function (sels) {
        this.sels = sels;
      },

      getDictionarys:function(){
          var that=this;
          reqGetDictionaryListByCode({code:'columns_type'}).then((res) => {
              that.columnsTypeArray = res.data.data;
          });
      },

      goReset(){
          this.filters.name='';
          this.pageNum=1;
          this.reqGetBannerListPage();
      },

      // 删除
      delBanner: function(index, row) {
        this.$confirm('确认删除该记录吗?', '提示', {type: 'warning'}).then(() => {
          this.listLoading = true;
          let para = {id: row.id};
          reqDeleteBanner(para).then((res) => {
           if(res.data.code===1){
             this.listLoading = false;
             this.$message({
               message: '删除成功',
               type: 'success'
             });
             this.reqGetBannerListPage();
           }else {
             this.listLoading = false;
             this.$message({
               message: '删除失败',
               type: 'error'
             });
             this.reqGetBannerListPage();
           }
          });
        }).catch(() => {
        });
      },
      // 批量删除
      batchDeleteBanner: function () {
        var ids = this.sels.map(item => item.id).toString();
        this.$confirm('确认删除选中记录吗？', '提示', {
          type: 'warning'
        }).then(() => {
          this.listLoading = true;
          let para = {ids: ids};
          reqBatchDeleteBanner(para).then((res) => {
           if(res.data.code===1){
             this.listLoading = false;
             this.$message({
               message: '删除成功',
               type: 'success'
             });
             this.reqGetBannerListPage();
           }else {
             this.listLoading = false;
             this.$message({
               message: '删除失败',
               type: 'error'
             });
             this.reqGetBannerListPage();
           }
          });
        }).catch(() => {

        });
      },

    },mounted(){
      // this.getDictionarys();
      this.reqGetBannerListPage();
      var permissionId = sessionStorage.getItem("permissionId");
      permission.getPermissionButton(permissionId,this.permissionButton);
    }
  }
</script>
